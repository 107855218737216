'use client'

import { Button, Flex, GridItem, Stack, Text } from '@chakra-ui/react'
import ClockIcon from '@components/icons/ClockIcon'
import { useAppSetupStore } from 'store/appSetup'
import Logo from '@components/Logo'
import { useTranslations } from 'next-intl'
import { useEffect } from 'react'
import { useRouter } from 'next/navigation'

export default function Error({ error }: { error: Error & { digest?: string } }) {
  const t = useTranslations()
  const colors = useAppSetupStore((state) => state.colors)
  const router = useRouter()

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log({ error })

    if (error?.message === 'NEXT_REDIRECT') {
      router.replace('/api/auth/logout')
    }
  }, [error, router])

  return (
    <GridItem area={'main'} overflow="auto">
      <Stack
        bgColor={colors.background.special_background[0]}
        gap={{ base: '80px', sm: '120px' }}
        gridArea={'main'}
        h={'100vh'}
        p={{ base: '40px', sm: '50px' }}
      >
        <Flex justifyContent={'center'}>
          <Logo type="dashboardLogo" />
        </Flex>
        <Stack justifyContent={'center'}>
          <Stack alignItems={'center'} gap={{ base: 7, sm: 6 }}>
            <ClockIcon color={colors.error[500]} />
            <Stack alignItems={'center'} className="cta" gap={4} justifyContent={'center'}>
              <Text
                fontSize={{ base: 'xl', xl: 'xxl' }}
                fontWeight={500}
                maxWidth={{ sm: '100%', lg: '600px' }}
                mt={{ base: 4, xl: 6 }}
                textAlign={'center'}
              >
                {t('errorMessage.sessionExpired_title')}
              </Text>
            </Stack>
            <Stack>
              <Button
                aria-label={'logout'}
                as="a"
                href="/api/auth/logout"
                px={12}
                size="xl"
                variant={'outline'}
                w="100%"
              >
                {t('buttons.back_to_login')}
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </GridItem>
  )
}
